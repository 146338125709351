<template>
  <div class="container">
    <div class="stripe"></div>
    <div class="main">
      <div class="flag">
        <img class="icon" src="https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/01870d81-23cb-38e3-4692-3c69633d6ed7?x-oss-process=style/origin" alt="icon">
        <img class="text" src="https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/01870d81-23ca-01de-1ff4-efd615dae470?x-oss-process=style/origin" alt="text">
      </div>
      <div class="middle">
        <div class="user">
          <img class="avatar" :src="avatarUrl" alt="avatar">
          <div class="nickname">{{ nickname }}</div>
        </div>
        <div class="honors">
          <div v-for="achievement of achievements" :key="achievement.category" class="honor">
            <div class="left">
              <span>{{ achievement.totalResult }}</span>
              <span>{{ achievement.category }}</span>
            </div>
            <img class="icon" :src="achievement.icon" alt="icon">
          </div>
        </div>
      </div>
      <div :class="['footer', !userMedalList.length ? 'footer-placeholder' : '']">
        <span v-if="!userMedalList.length">还没有获得勋章~</span>
        <template v-else>
          <img v-for="medal in userMedalList" :key="medal.medal.id" class="medal" :src="medal.medal.logo.url" alt="medal">
        </template>
      </div>
    </div>
    <div class="stripe"></div>
    <div class="footer">
      <img class="qrcode" :src="qrcode" alt="">
    </div>
  </div>
</template>

<script>
import $fetch from '@/utils/fetch.js';
export default {
  name: "leaguesCareer",
  data() {
    return {
      userId: "",
      avatarUrl: "",
      nickname: "",
      achievements: [],
      userMedalList: [],
      qrcode: "",
    }
  },
  created() {
    let { userId, avatarUrl, nickname, achievements, userMedalList } = this.$route.query;
    this.userId = userId;
    this.avatarUrl = avatarUrl;
    this.nickname = nickname;
    this.achievements = JSON.parse(achievements).slice(0, 3);
    this.userMedalList = JSON.parse(userMedalList).slice(0, 3);
    this.EncodeCumenId(userId);
  },
  methods: {
    EncodeCumenId(id) {
      $fetch
        .post("EncodeCumenId", { id })
        .then((res) => {
          this.getWechatCode(res.id);
        });
    },
    getWechatCode(scene) {
      const _ = this;
      $fetch
        .post("GetWeixinQRCode", {
          page: 'packages/leagues/pages/career/index',
          scene,
          width: 80,
          is_hyaline: true,
        })
        .then((code) => {
          _.qrcode = "data:image/jpeg;base64, " + code.image;
          console.log(_.qrcode)
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
$multipleCount: 1.122;
$color-green: #74ffbb;

.container {
  width: 100vw;
  height: 152.69vw;
  overflow: hidden;

  .stripe {
    width: 100%;
    height: px2rem(36 * $multipleCount);
    background-image: repeating-linear-gradient(-45deg, #222 0px, #222 14px, $color-green 0px, $color-green 22px);
  }

  .main {
    width: 100%;
    height: px2rem(780 * $multipleCount);
    background: linear-gradient(178deg, $color-green px2rem(144 * $multipleCount), #fff px2rem(144 * $multipleCount), #d5fbf9 53%, #effff7 100%);
    overflow: hidden;

    .flag {
      @include row(flex-start, center);

      .icon {
        width: px2rem(80 * $multipleCount);
        height: px2rem(80 * $multipleCount);
        margin-left: px2rem(20 * $multipleCount);
      }

      .text {
        width: px2rem(176 * $multipleCount);
        height: px2rem(62 * $multipleCount);
        margin-left: px2rem(8 * $multipleCount);
        object-fit: contain;
      }
    }

    .middle {
      $padding-aside: px2rem(24 * $multipleCount);

      @include row(space-between, flex-start);
      width: calc(100% - 2 * $padding-aside);
      padding: 0 $padding-aside;

      .user {
        position: relative;
        width: px2rem(360 * $multipleCount);
        height: px2rem(438 * $multipleCount);
        margin-top: px2rem(16 * $multipleCount);
        background-color: #fff;
        clip-path: polygon(
          0 px2rem(24 * $multipleCount),
          0 100%,
          100% calc(100% - px2rem(24 * $multipleCount)),
          100% 0
        );

        > .avatar {
          width: 100%;
          height: 100%;
          object-fit: cover
        }

        > .nickname {
          @include ellipsis;
          position: absolute;
          left: 0;
          bottom: px2rem(12 * $multipleCount);
          width: 100%;
          padding: 0 px2rem(28 * $multipleCount);
          height: px2rem(56 * $multipleCount);
          line-height: px2rem(56 * $multipleCount);
          color: #fff;
          font-size: px2rem(34 * $multipleCount);
          font-weight: 1000;
          transform: rotate(-3.6deg);
          font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
          background-color: #000;
          box-sizing: border-box
        }
      }

      .honors {
        @include column;
        margin-top: px2rem(-2 * $multipleCount);

        > .honor {
          @include row(space-between, flex-start);
          width: px2rem(236 * $multipleCount);
          height: px2rem(144 * $multipleCount);
          background-color: #fff;
          clip-path: polygon(
            0 px2rem(16 * $multipleCount),
            0 100%,
            100% calc(100% - px2rem(16 * $multipleCount)),
            100% 0
          );

          > .left {
            @include column;

            > span {
              margin-left: px2rem(24 * $multipleCount);

              &:first-child {
                font-size: px2rem(40 * $multipleCount);
                line-height: px2rem(56 * $multipleCount);
                font-weight: 1000;
                color: #222;
                font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
                margin-top: px2rem(16 * $multipleCount);
              }

              &:last-child {
                font-size: px2rem(28 * $multipleCount);
                line-height: px2rem(28 * $multipleCount);
                color: #969898;
                margin-top: px2rem(4 * $multipleCount);
              }
            }
          }

          > .icon {
            width: px2rem(64 * $multipleCount);
            height: px2rem(64 * $multipleCount);
            margin-top: px2rem(36 * $multipleCount);
            margin-right: px2rem(4 * $multipleCount);
          }
        }
      }
    }

    > .footer {
      @include row;
      $margin-gap: px2rem(24 * $multipleCount);

      width: calc(100% - $margin-gap * 2 - px2rem(20 * $multipleCount) * 2);
      height: px2rem(240 * $multipleCount);
      margin-left: $margin-gap;
      margin-top: px2rem(-20 * $multipleCount);
      padding: 0 px2rem(20 * $multipleCount);
      background-color: #fff;
      clip-path: polygon(
        0 px2rem(40 * $multipleCount),
        0 100%,
        100% 100%,
        100% 0
      );

      > span:first-child {
        font-family: PingFang SC;
        font-size: px2rem(24 * $multipleCount);
        line-height: px2rem(24 * $multipleCount);
        color: #c2c2c2;
      }

      > .medal {
        width: px2rem(176 * $multipleCount);
        height: px2rem(176 * $multipleCount);
        margin-top: px2rem(46 * $multipleCount);
        object-fit: contain;

        &:not(:first-child) {
          margin-left: px2rem(18.347 * $multipleCount);
        }
      }
    }

    > .footer-placeholder {
      @include row(center, center);
    }
  }

  > .footer {
    position: relative;
    width: 100%;
    height: px2rem(168 * $multipleCount);
    background: url("https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/01870dd8-59d9-a282-3787-31c77a13a990?x-oss-process=style/origin") no-repeat left top / cover;

    > .qrcode {
      position: absolute;
      right: px2rem(32 * $multipleCount);
      top: px2rem(24 * $multipleCount);
      width: px2rem(112 * $multipleCount);
      height: px2rem(112 * $multipleCount);
    }
  }
}
</style>
